.greyBackground {
  background-color: #eaeaea;
}

.tweetContainer {
  text-align: center;
  /* padding: 20px 0; */
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.tweet {
  background-color: white;
  border: 2px solid #cfd9de;
  border-radius: 12px;
  margin: 10px auto;
  max-width: 700px;
}

.tweetHeader {
  display: flex;
  flex-direction: row;
  padding: 15px;
}

.authorProfilePic {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.authorDetails {
  margin-left: 10px;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
}

.authorDetails > div:first-child {
  font-weight: bold;
}

.authorDetails > div:last-child {
  color: #6e767d;
}

.tweetDetails {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.tweetDetails > div:first-child {
  font-weight: bold;
}

.tweetDetails > div:last-child {
  color: #6e767d;
  text-decoration: underline;
}

.tweetText {
  padding: 15px;
  padding-top: 0;
  text-align: left;
  white-space: pre-wrap;
}

.tweetFooter {
  display: flex;
  flex-direction: row;
  padding: 15px;
  text-align: center;
  justify-content: center;
}

.tweetFooter > button {
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.tweetFooter > button:hover {
  background-color: rgba(211, 211, 211, 0.603);
}

.tweetFooter > button:first-child {
  margin: 0 auto;
}

.tweetFooter > button > svg {
  margin: auto 0;
  height: 100%;
}

.unstyledLink {
  color: black;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.greyLink {
  color: #6e767d;
  cursor: pointer;
  text-decoration: none;
}

.greyLink:hover {
  text-decoration: underline;
}

.tweetImage {
  width: 50%;
  margin-top: 1rem;
}
